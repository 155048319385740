import React from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";

const CustomLink = ({ slug, url, children }) => {
  const isInternal = slug && slug.length > 0;
  // if the slug links to an element on this page
  const isOnPage = slug && slug.indexOf("#") === 0;
  if (isOnPage) {
    const element = slug.substring(1);
    return (
      <ScrollLink smooth to={element}>
        {children}
      </ScrollLink>
    );
  }

  const linkTo = slug === "/" ? "/" : `/${slug}`;

  if (isInternal) {
    return <Link to={linkTo}>{children}</Link>;
  } else return <a href={url}>{children}</a>;
};

export default CustomLink;
