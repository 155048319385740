import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Layout, Menu, Drawer, Button } from "antd";
import { InstagramFilled } from "@ant-design/icons";
import { CustomLink } from "../../Elements";
import IconArrowDown from "../../../assets/icons/small-down.svg";
import "./styles.less";
import { isMobile } from "../../../utils";
import { useDeviceDetect } from "../../../utils/hooks";

import Icon from "@ant-design/icons";

const { Header } = Layout;
const { SubMenu } = Menu;

const RightMenu = (props) => (
  <div className="right-menu">
    {props.socials &&
      props.socials.map((elem, i) => (
        <a key={`social-${i}`} href={elem.link}>
          {elem.type === "instagram" ? <InstagramFilled /> : elem.type}
        </a>
      ))}
  </div>
);

const LeftMenu = ({ items, activePath }) => {
  return (
    <Menu
      className="left-menu"
      mode={!isMobile() ? "horizontal" : "inline"}
      selectedKeys={[activePath]}
    >
      {items &&
        items.map((item) =>
          item.elements ? (
            <SubMenu
              key={item.id}
              title={
                <span className="submenu-title">
                  {item.title}
                  <Icon component={IconArrowDown} />
                </span>
              }
            >
              {item.elements.map((subItem) => (
                <Menu.Item key={`sub-${subItem.id}`}>
                  <CustomLink url={subItem.url} slug={subItem.slug}>
                    {subItem.title}
                  </CustomLink>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={item.id}>
              <CustomLink url={item.url} slug={item.slug}>
                {item.title}
              </CustomLink>
            </Menu.Item>
          )
        )}
    </Menu>
  );
};

const PageHeader = ({ data, showLogo, logoClass }) => {
  // select the right locale
  const { instagram } = data.contentfulMetaInformation;
  const { nodes: intlNodes } = data.allContentfulNavigation;
  const selectedMenu = intlNodes.find((node) => node.node_locale === "en-US");
  const { elements } = selectedMenu;
  const menuItems = elements;
  const { isMobile } = useDeviceDetect();

  return (
    <Header className="page-header">
      <div className="nav-bar">
        <div className="container">
          <nav className="menu-bar">
            <div className="menu-con">
              <LeftMenu items={menuItems} />
              {!isMobile && (
                <RightMenu socials={[{ type: "instagram", link: instagram }]} />
              )}
            </div>
          </nav>
        </div>
      </div>
      {showLogo && (
        <div className={`logo ${logoClass || ""}`}>
          <Link to="/">
            <img src={`/img/logo@2x.png`} />
          </Link>
        </div>
      )}
    </Header>
  );
};

const DataWrapper = (props) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulMetaInformation(metaId: { eq: "Basic" }) {
        instagram
      }
      allContentfulNavigation(filter: { menuId: { eq: "mainMenu" } }) {
        nodes {
          id
          node_locale
          elements {
            ... on ContentfulNavigation {
              id
              title
              elements {
                ... on ContentfulNavigationElement {
                  id
                  slug
                  url
                  title
                }
              }
            }
            ... on ContentfulNavigationElement {
              id
              slug
              url
              title
            }
          }
        }
      }
    }
  `);
  return <PageHeader data={data} {...props} />;
};

export default DataWrapper;
