module.exports = {
  siteTitle: "Léa Wormsbach",
  siteTitleAlt: "Léa Wormsbach", // This allows an alternative site title for SEO schema.
  publisher: "Léa Wormsbach", // Organization name used for SEO schema
  siteDescription:
    "Léa Wormsbach is a Berlin based photographer who's work spans across a wide range of styles, including fashion, portraiture, documentary & still-life. Coming from a small town in Germany, Léa is currently living in Berlin and has built up a reputation in the international fashion arena, working for several of fashion’s leading magazines including VOGUE, Harper’s Bazaar, L’Officiel, GQ and many more.",
  siteUrl: "https://www.leawormsbach.com", // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: "Timo Müller", // Author for RSS author segment and SEO schema
  authorUrl: "", // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: "", // Change for Twitter Cards
  shortTitle: "Léa Portfolio", // Used for App manifest e.g. Mobile Home Screen
  shareImage: "/logos/share.jpg", // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: "/logos/logo-512.png", // Logo used for SEO, RSS, and App manifest
  favicon: "/logos/favicon.png",
  backgroundColor: "#e9e9e9", // Used for Offline Manifest
  themeColor: "#121212", // Used for Offline Manifest
  copyright: "Copyright © 2021", // Copyright string for the RSS feed
};
