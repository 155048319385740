export const MOBILE_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 992;

export function commentToHtml(htmlString) {
  return htmlString.replace(/<!--/g, "").replace(/-->/g, "");
  // .replace(/\n/g, '<br />')
}

export const isBrowser = () => typeof window !== "undefined";

export function isMobile() {
  if (isBrowser()) {
    const isMobile = window.matchMedia("only screen and (max-width: 767px)");
    return isMobile.matches;
  } else return false;
}
