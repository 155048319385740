import React from "react";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import config from "../../utils/siteConfig";
import PageHeader from "./PageHeader";
import { Element } from "react-scroll";

// Main styles
import "../../assets/less/styles.less";

const Layout = ({ children, showLogo, logoClass, hideMenu }) => {
  return (
    <div className="siteRoot">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon} />
      </Helmet>
      {hideMenu ? (
        <>{children}</>
      ) : (
        <div id="header" className="siteContent">
          <PageHeader showLogo={showLogo} logoClass={logoClass} />

          {children}
        </div>
      )}
    </div>
  );
};

export default Layout;
